<template>
	<div class="home">
		<Headbar bgColor="#111111" :isBack="false" title="首页">
			<template #right>
				<img src="@/assets/img/base/language.png" class="language-icon" />
			</template>
		</Headbar>

		<div class="swipe">
			<van-swipe :autoplay="3000" indicator-color="white">
				<van-swipe-item v-for="item in slideList" :key="item.id">
					<img :src="getimg(item.img)" class="swipe-img" />
				</van-swipe-item>
			</van-swipe>
		</div>

		<div class="q-mt-xs">
			<van-notice-bar left-icon="volume-o" background="transparent" color="#fff" mode="link" :text="marqueemsg"
				@click="tolink('News')" />
		</div>

		<div class="menu">
			<div class="menu-item" @click="tolink('financial')">
				<img src="@/assets/img/base/home-menu1.png" class="menu-icon" />
				<div>理财投资</div>
			</div>
			<div class="menu-item" @click="tolink('News')">
				<img src="@/assets/img/base/home-menu2.png" class="menu-icon" />
				<div>公告</div>
			</div>
			<div class="menu-item" @click="tolink('Chongzhi')">
				<img src="@/assets/img/base/home-menu3.png" class="menu-icon" />
				<div>充币</div>
			</div>
			<div class="menu-item" @click="tolink('Tixian')">
				<img src="@/assets/img/base/home-menu4.png" class="menu-icon" />
				<div>提币</div>
			</div>
		</div>

		<div class="tx-list">
			<div class="tx-item" v-for="(item, index) in 10" :key="index">
				<div>2023-10-31</div>
				<div>admin</div>
				<div class="tx-cell">
					<div>
						<div style="font-size: 12px;">Amount</div>
						<div style="color: #00B77F;">100</div>
					</div>
					<div>
						<div style="color: #fff;text-align: right;">DG</div>
					</div>
				</div>
			</div>
		</div>

		<div class="market">
			<div class="market-list">
				<div class="market-label">
					<div>交易对</div>
					<div class="text-right">最新价格</div>
				</div>
				<div class="market-val" v-for="item in marketList" :key="item.id">
					<div class="market-row">
						<div class="market-name">
							<img :src="require('@/assets/img/coin/' + item.symbol + '.png')" style="width: 25px;margin-right: 10px;">
							{{ item.symbol }} / USDT
						</div>
					</div>
					<div class="text-right">{{ item.priceOut.toFixed(6) }}</div>
				</div>
			</div>
		</div>

		<CheckRegister></CheckRegister>

		<TabBar active="0"></TabBar>
	</div>
</template>

<script>
import { Swipe, SwipeItem, NoticeBar } from 'vant';
import CustomComponent from "@/components/CustomComponent";
import TabBar from '@/components/TabBar'
import CheckRegister from '@/components/CheckRegister'
import { ref } from 'vue';

export default {
	name: '',
	components: {
		[Swipe.name]: Swipe,
		[SwipeItem.name]: SwipeItem,
		[NoticeBar.name]: NoticeBar,
		TabBar,
		CheckRegister
	},
	setup() {
		return {
			slideList: ref([]),
			marqueemsg: ref(""),
			marketList: ref([])
		}
	},
	created() {
		this.getslide()
		this.getgg()
		this.getEthContract()
	},
	methods: {
		tolink(path) {
			this.$router.push(path)
		},
		getimg: function (img) {
			if (img != "") {
				return this.$config.send_url + "upload/" + img;
			}
		},
		getgg() {
			let _this = this
			_this.$request.post(
				"Api/News/Gonggao",
				{},
				(res) => {
					if (res.data.code == 0) {
						_this.$q.dialog({
							component: CustomComponent,
							componentProps: {
								messages: [res.data.msg],
								persistent: true,
							}
						});
						return
					}
					let data = res.data.data
					_this.marqueemsg = data
				}
			)
		},
		getslide() {
			let _this = this
			_this.$request.post(
				"Api/Slide/List",
				{
					lx: 1,
				},
				(res) => {
					if (res.data.code == 0) {
						_this.$q.dialog({
							component: CustomComponent,
							componentProps: {
								messages: [res.data.msg],
								persistent: true,
							}
						});
						return
					}
					let data = res.data.data
					_this.slideList = data
				}
			)
		},
		getEthContract() {
      let _this = this
      _this.$request.post(
        "api/EthContract/List",
        {},
        (res) => {
          if (res.data.code == 0) {
            _this.$q.dialog({
              component: CustomComponent,
              componentProps: {
                messages: [res.data.msg],
                persistent: true,
              }
            });
          }
					let data = res.data.data
					let list = data.coins
					list.unshift({
						symbol: 'DG',
						priceOut: data.dg
					})
					_this.marketList = list
        }
      )
    },
	}
}
</script>

<style scoped>
.home {
	padding: 0 10px;
}

.language-icon {
	width: 17px;
}

.swipe {
	margin-top: 10px;
}

.swipe-img {
	width: 100%;
	border-radius: 12px;
}

.menu {
	margin-top: 10px;
	display: flex;
	flex-wrap: wrap;
}

.menu-item {
	width: 25%;
	text-align: center;
	color: #fff;
	line-height: 15px;
}

.menu-icon {
	width: 40px;
	height: 40px;
}

.tx-list {
	margin-top: 15px;
	display: flex;
	overflow-x: auto;
}

.tx-item {
	min-width: 150px;
	margin-right: 6px;
	padding: 6px;
	font-size: 13px;
	border-radius: 4px;
	color: #a8a8a8;
	background-color: #292929;
}

.tx-item:last-child {
	margin: 0;
}

.tx-cell {
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
}

.market {
	padding: 10px 0;
}

.market-title {
	color: #fff;
	font-size: 15px;
	font-weight: bold;
}

.market-label {
	margin-top: 10px;
	padding-top: 10px;
	display: flex;
	color: #fff;
	border-top: 1px solid #1A1A1A;
}

.market-label>div {
	flex: 1;
}

.tag {
	width: 70px;
	height: 24px;
	line-height: 24px;
	font-size: 13px;
	color: #fff;
	text-align: center;
	border-radius: 4px;
	background-color: #ff6059;
}

.tag-default {
	background-color: gray;
}

.tag-success {
	background-color: #2bbc6a;
}

.tag-error {
	background-color: #f56c6c;
}

.market-val {
	margin-top: 15px;
	display: flex;
	align-items: center;
}

.market-val>div {
	flex: 1;
	color: #fff;
}

.market-icon {
	margin-right: 6px;
	width: 28px;
	height: 28px;
}

.market-row {
	display: flex;
	align-items: center;
}

.text-right {
	text-align: right;
}

.market-name {
	display: flex;
	align-items: center;
}
</style>